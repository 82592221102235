.hero-img {
    max-width: 100%;
    margin-top: 2rem;
    margin: 3rem 0;
    z-index: 2;

    @include respond-to('medium') {
        margin: 8rem auto;
    }
    @include respond-to('large') {
        margin: 0;
    }
}

.section-img {
    max-width: 100%;
    margin: 4rem auto 0;
    opacity: 0;

    &.section-img--wide {
        max-width: 150%;
        margin: 4rem 0 0 -25%;
    }
    @include respond-to('medium') {
        max-width: 75%;
    }
    @include respond-to('large') {
        max-width: 100%;
    }
    @include respond-to('x-large') {
        max-width: 800px;
    }
}

img.feature-img {
    max-width: 100%;


    @include respond-to('large') {
        margin: 0 auto;

        &.skew-left {
            transform: perspective(1000px) rotateY(8deg) translateX(5%);
        }
        &.skew-right {
            transform: perspective(1000px) rotateY(-8deg) translateX(5%);
        }
    }
}