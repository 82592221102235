*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  line-height: 1.4;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 2rem 0 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: $blue;
}

img {
  display: block;
}

ul.large-text-list {
  padding: 0;
  list-style: none;

  li {
    display: flex !important;
    align-items: center;
    padding: 1rem 0;
    font-weight: 600;

    &.centered {
      justify-content: center;
    }

    &.with-icon {
      &:before { 
      content: ''; 
      display: inline-block; 
      height: 1.5rem; 
      background-image: url('/assets/images/checked_icon.svg'); 
      background-size: contain; 
      background-repeat: no-repeat; 
      padding-left: 3.5rem;
      vertical-align: middle;
        stroke: $color-secondary;
      }
    }
  }
}

label {
  display: block;
}

input, textarea {
  display: block;
  width: 100%;
  padding: 1rem 1.75rem;
  margin: 1rem 0;
  border: 4px solid #D6EDEB;
  border-radius: 8px;
  transition: all 0.25s linear;

  &:focus, &:active, &:hover {
    outline: none;
    border: 4px solid $color-secondary;
  }
}

textarea {
  resize: vertical;
}

.svg-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    margin-right: 2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 4rem);
  width: calc(100vw - 4rem);
  margin: 2rem;
  border-radius: 25px;
  background: #fff;
  z-index: 999;
  box-shadow: 0 15px 40px rgba(0,0,0, 0.15);
  transform: scale(0);
  transform-origin: center;

  @include respond-to('large') {
    height: calc(100vh - 8rem);
    width: calc(100vw - 8rem);
    margin: 4rem;
  }

  &.active {
    transform: scale(1);
  }

  .modal-content {
    padding-top: 8rem;
    height: 100%;
  }

  &.active .modal-content {
    display: block;
  }
}

.iframe-wrapper {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  z-index: 1;

  iframe {
    height: 100%;
    width: 100%;
  }
}

.social-wrapper {
  position: absolute;
  bottom: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  padding-left: 1rem;

  img {
    margin-bottom: 1rem;
  }

  @include respond-to('large') {
    position: relative;
    bottom: auto;
    margin-top: 4rem;
  }
  

  &:after {
    content: '';
    position: absolute;
    bottom: -8rem;
    left: calc(2rem - 1px);
    height: 7rem;
    width: 2px;
    background: $light-grey;
  }
}

// cookie transition in and out
.cookies-message {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 4.5rem;
    width: 100%;
    max-height: 50px;
    overflow: hidden;
  
    @include respond-to('large') {
        bottom: 0;
    }
  
    p {
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        padding: 10px;
        margin-top: 0%;
        .read-more {
          color:#013368;
        }
    }
}

