.a-fade {
    opacity: 1;
    transition: all 0.5s ease-out;
}

html.is-rendering .a-fade {
    opacity: 1;
}
html.is-leaving .a-fade {
    opacity: 0;
}

.fade-in {
    animation: fade-in 0.5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
}

@keyframes fade-in {
    0% {
        opacity: (0);
    }
    100% {
        opacity: (1);
    }
}

.fade-in-up {
    animation: fade-in-up 1s both;
    transform-origin: top;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

@keyframes fade-in-up {
    0% {
        transform: translateY(50px);
        opacity: (0);
    }
    100% {
        transform: translateY(0);
        opacity: (1);
    }
}

@for $i from 2 through 10 {
    main .fade-in-up:nth-child(#{$i}n) {
        animation-duration: #{$i * .25}s;
    }
}

.fade-out-down {
    animation: fade-out-down 1s both;
    transform-origin: top;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

@keyframes fade-out-down {
    0% {
        transform: translateY(0);
        opacity: (1);
    }
    100% {
        transform: translateY(50px);
        opacity: (0);
    }
}
