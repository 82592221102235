$font-heading: 'Montserrat', sans-serif;
$font-body: 'Montserrat', sans-serif;


html {
  @include responsive-font(0.9vw, 10px, 22px, 10px);
}

body {
  font-family: $font-body;
  font-weight: 500;
  color: $color-typography;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  font-weight: 800;
  line-height: 1
}

h1 {
  font-size: 3.75rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 3rem;
}
