.icon {
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  height: 1em;
  position: relative;
  text-indent: -9999px;
  vertical-align: middle;
  width: 1em; }
  .icon::before, .icon::after {
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); }
  .icon.icon-2x {
    font-size: 1.6rem; }
  .icon.icon-3x {
    font-size: 2.4rem; }
  .icon.icon-4x {
    font-size: 3.2rem; }

.accordion .icon,
.btn .icon,
.toast .icon,
.menu .icon {
  vertical-align: -10%; }

.btn-lg .icon {
  vertical-align: -15%; }

.icon-arrow-down::before,
.icon-arrow-left::before,
.icon-arrow-right::before,
.icon-arrow-up::before,
.icon-downward::before,
.icon-back::before,
.icon-forward::before,
.icon-upward::before {
  border: 0.1rem solid currentColor;
  border-bottom: 0;
  border-right: 0;
  height: .65em;
  width: .65em; }

.icon-arrow-down::before {
  transform: translate(-50%, -75%) rotate(225deg); }

.icon-arrow-left::before {
  transform: translate(-25%, -50%) rotate(-45deg); }

.icon-arrow-right::before {
  transform: translate(-75%, -50%) rotate(135deg); }

.icon-arrow-up::before {
  transform: translate(-50%, -25%) rotate(45deg); }

.icon-back::after,
.icon-forward::after {
  background: currentColor;
  height: 0.1rem;
  width: .8em; }

.icon-downward::after,
.icon-upward::after {
  background: currentColor;
  height: .8em;
  width: 0.1rem; }

.icon-back::after {
  left: 55%; }

.icon-back::before {
  transform: translate(-50%, -50%) rotate(-45deg); }

.icon-downward::after {
  top: 45%; }

.icon-downward::before {
  transform: translate(-50%, -50%) rotate(-135deg); }

.icon-forward::after {
  left: 45%; }

.icon-forward::before {
  transform: translate(-50%, -50%) rotate(135deg); }

.icon-upward::after {
  top: 55%; }

.icon-upward::before {
  transform: translate(-50%, -50%) rotate(45deg); }

.icon-caret::before {
  border-top: .3em solid currentColor;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  height: 0;
  transform: translate(-50%, -25%);
  width: 0; }

.icon-menu::before {
  background: currentColor;
  box-shadow: 0 -.35em, 0 .35em;
  height: 0.1rem;
  width: 100%; }

.icon-apps::before {
  background: currentColor;
  box-shadow: -.35em -.35em, -.35em 0, -.35em .35em, 0 -.35em, 0 .35em, .35em -.35em, .35em 0, .35em .35em;
  height: 3px;
  width: 3px; }

.icon-resize-horiz::before, .icon-resize-horiz::after,
.icon-resize-vert::before,
.icon-resize-vert::after {
  border: 0.1rem solid currentColor;
  border-bottom: 0;
  border-right: 0;
  height: .45em;
  width: .45em; }

.icon-resize-horiz::before,
.icon-resize-vert::before {
  transform: translate(-50%, -90%) rotate(45deg); }

.icon-resize-horiz::after,
.icon-resize-vert::after {
  transform: translate(-50%, -10%) rotate(225deg); }

.icon-resize-horiz::before {
  transform: translate(-90%, -50%) rotate(-45deg); }

.icon-resize-horiz::after {
  transform: translate(-10%, -50%) rotate(135deg); }

.icon-more-horiz::before,
.icon-more-vert::before {
  background: currentColor;
  box-shadow: -.4em 0, .4em 0;
  border-radius: 50%;
  height: 3px;
  width: 3px; }

.icon-more-vert::before {
  box-shadow: 0 -.4em, 0 .4em; }

.icon-plus::before,
.icon-minus::before,
.icon-cross::before {
  background: currentColor;
  height: 0.1rem;
  width: 100%; }

.icon-plus::after,
.icon-cross::after {
  background: currentColor;
  height: 100%;
  width: 0.1rem; }

.icon-cross::before {
  width: 100%; }

.icon-cross::after {
  height: 100%; }

.icon-cross::before, .icon-cross::after {
  transform: translate(-50%, -50%) rotate(45deg); }

.icon-check::before {
  border: 0.1rem solid currentColor;
  border-right: 0;
  border-top: 0;
  height: .5em;
  width: .9em;
  transform: translate(-50%, -75%) rotate(-45deg); }

.icon-stop {
  border: 0.1rem solid currentColor;
  border-radius: 50%; }
  .icon-stop::before {
    background: currentColor;
    height: 0.1rem;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 1em; }

.icon-shutdown {
  border: 0.1rem solid currentColor;
  border-radius: 50%;
  border-top-color: transparent; }
  .icon-shutdown::before {
    background: currentColor;
    content: "";
    height: .5em;
    top: .1em;
    width: 0.1rem; }

.icon-refresh::before {
  border: 0.1rem solid currentColor;
  border-radius: 50%;
  border-right-color: transparent;
  height: 1em;
  width: 1em; }

.icon-refresh::after {
  border: .2em solid currentColor;
  border-top-color: transparent;
  border-left-color: transparent;
  height: 0;
  left: 80%;
  top: 20%;
  width: 0; }

.icon-search::before {
  border: 0.1rem solid currentColor;
  border-radius: 50%;
  height: .75em;
  left: 5%;
  top: 5%;
  transform: translate(0, 0) rotate(45deg);
  width: .75em; }

.icon-search::after {
  background: currentColor;
  height: 0.1rem;
  left: 80%;
  top: 80%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: .4em; }

.icon-edit::before {
  border: 0.1rem solid currentColor;
  height: .4em;
  transform: translate(-40%, -60%) rotate(-45deg);
  width: .85em; }

.icon-edit::after {
  border: .15em solid currentColor;
  border-top-color: transparent;
  border-right-color: transparent;
  height: 0;
  left: 5%;
  top: 95%;
  transform: translate(0, -100%);
  width: 0; }

.icon-delete::before {
  border: 0.1rem solid currentColor;
  border-bottom-left-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  border-top: 0;
  height: .75em;
  top: 60%;
  width: .75em; }

.icon-delete::after {
  background: currentColor;
  box-shadow: -.25em .2em, .25em .2em;
  height: 0.1rem;
  top: 0.05rem;
  width: .5em; }

.icon-share {
  border: 0.1rem solid currentColor;
  border-radius: 0.1rem;
  border-right: 0;
  border-top: 0; }
  .icon-share::before {
    border: 0.1rem solid currentColor;
    border-left: 0;
    border-top: 0;
    height: .4em;
    left: 100%;
    top: .25em;
    transform: translate(-125%, -50%) rotate(-45deg);
    width: .4em; }
  .icon-share::after {
    border: 0.1rem solid currentColor;
    border-bottom: 0;
    border-right: 0;
    border-radius: 75% 0;
    height: .5em;
    width: .6em; }

.icon-flag::before {
  background: currentColor;
  height: 1em;
  left: 15%;
  width: 0.1rem; }

.icon-flag::after {
  border: 0.1rem solid currentColor;
  border-bottom-right-radius: 0.1rem;
  border-left: 0;
  border-top-right-radius: 0.1rem;
  height: .65em;
  top: 35%;
  left: 60%;
  width: .8em; }

.icon-bookmark::before {
  border: 0.1rem solid currentColor;
  border-bottom: 0;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  height: .9em;
  width: .8em; }

.icon-bookmark::after {
  border: 0.1rem solid currentColor;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0.1rem;
  height: .5em;
  transform: translate(-50%, 35%) rotate(-45deg) skew(15deg, 15deg);
  width: .5em; }

.icon-download,
.icon-upload {
  border-bottom: 0.1rem solid currentColor; }
  .icon-download::before,
  .icon-upload::before {
    border: 0.1rem solid currentColor;
    border-bottom: 0;
    border-right: 0;
    height: .5em;
    width: .5em;
    transform: translate(-50%, -60%) rotate(-135deg); }
  .icon-download::after,
  .icon-upload::after {
    background: currentColor;
    height: .6em;
    top: 40%;
    width: 0.1rem; }

.icon-upload::before {
  transform: translate(-50%, -60%) rotate(45deg); }

.icon-upload::after {
  top: 50%; }

.icon-copy::before {
  border: 0.1rem solid currentColor;
  border-radius: 0.1rem;
  border-right: 0;
  border-bottom: 0;
  height: .8em;
  left: 40%;
  top: 35%;
  width: .8em; }

.icon-copy::after {
  border: 0.1rem solid currentColor;
  border-radius: 0.1rem;
  height: .8em;
  left: 60%;
  top: 60%;
  width: .8em; }

.icon-time {
  border: 0.1rem solid currentColor;
  border-radius: 50%; }
  .icon-time::before {
    background: currentColor;
    height: .4em;
    transform: translate(-50%, -75%);
    width: 0.1rem; }
  .icon-time::after {
    background: currentColor;
    height: .3em;
    transform: translate(-50%, -75%) rotate(90deg);
    transform-origin: 50% 90%;
    width: 0.1rem; }

.icon-mail::before {
  border: 0.1rem solid currentColor;
  border-radius: 0.1rem;
  height: .8em;
  width: 1em; }

.icon-mail::after {
  border: 0.1rem solid currentColor;
  border-right: 0;
  border-top: 0;
  height: .5em;
  transform: translate(-50%, -90%) rotate(-45deg) skew(10deg, 10deg);
  width: .5em; }

.icon-people::before {
  border: 0.1rem solid currentColor;
  border-radius: 50%;
  height: .45em;
  top: 25%;
  width: .45em; }

.icon-people::after {
  border: 0.1rem solid currentColor;
  border-radius: 50% 50% 0 0;
  height: .4em;
  top: 75%;
  width: .9em; }

.icon-message {
  border: 0.1rem solid currentColor;
  border-bottom: 0;
  border-radius: 0.1rem;
  border-right: 0; }
  .icon-message::before {
    border: 0.1rem solid currentColor;
    border-bottom-right-radius: 0.1rem;
    border-left: 0;
    border-top: 0;
    height: .8em;
    left: 65%;
    top: 40%;
    width: .7em; }
  .icon-message::after {
    background: currentColor;
    border-radius: 0.1rem;
    height: .3em;
    left: 10%;
    top: 100%;
    transform: translate(0, -90%) rotate(45deg);
    width: 0.1rem; }

.icon-photo {
  border: 0.1rem solid currentColor;
  border-radius: 0.1rem; }
  .icon-photo::before {
    border: 0.1rem solid currentColor;
    border-radius: 50%;
    height: .25em;
    left: 35%;
    top: 35%;
    width: .25em; }
  .icon-photo::after {
    border: 0.1rem solid currentColor;
    border-bottom: 0;
    border-left: 0;
    height: .5em;
    left: 60%;
    transform: translate(-50%, 25%) rotate(-45deg);
    width: .5em; }

.icon-link::before, .icon-link::after {
  border: 0.1rem solid currentColor;
  border-radius: 5em 0 0 5em;
  border-right: 0;
  height: .5em;
  width: .75em; }

.icon-link::before {
  transform: translate(-70%, -45%) rotate(-45deg); }

.icon-link::after {
  transform: translate(-30%, -55%) rotate(135deg); }

.icon-location::before {
  border: 0.1rem solid currentColor;
  border-radius: 50% 50% 50% 0;
  height: .8em;
  transform: translate(-50%, -60%) rotate(-45deg);
  width: .8em; }

.icon-location::after {
  border: 0.1rem solid currentColor;
  border-radius: 50%;
  height: .2em;
  transform: translate(-50%, -80%);
  width: .2em; }

.icon-emoji {
  border: 0.1rem solid currentColor;
  border-radius: 50%; }
  .icon-emoji::before {
    border-radius: 50%;
    box-shadow: -.17em -.1em, .17em -.1em;
    height: .15em;
    width: .15em; }
  .icon-emoji::after {
    border: 0.1rem solid currentColor;
    border-bottom-color: transparent;
    border-radius: 50%;
    border-right-color: transparent;
    height: .5em;
    transform: translate(-50%, -40%) rotate(-135deg);
    width: .5em; }

.a-fade {
  opacity: 1;
  transition: all 0.5s ease-out; }

html.is-rendering .a-fade {
  opacity: 1; }

html.is-leaving .a-fade {
  opacity: 0; }

.fade-in {
  animation: fade-in 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in-up {
  animation: fade-in-up 1s both;
  transform-origin: top;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes fade-in-up {
  0% {
    transform: translateY(50px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

main .fade-in-up:nth-child(2n) {
  animation-duration: 0.5s; }

main .fade-in-up:nth-child(3n) {
  animation-duration: 0.75s; }

main .fade-in-up:nth-child(4n) {
  animation-duration: 1s; }

main .fade-in-up:nth-child(5n) {
  animation-duration: 1.25s; }

main .fade-in-up:nth-child(6n) {
  animation-duration: 1.5s; }

main .fade-in-up:nth-child(7n) {
  animation-duration: 1.75s; }

main .fade-in-up:nth-child(8n) {
  animation-duration: 2s; }

main .fade-in-up:nth-child(9n) {
  animation-duration: 2.25s; }

main .fade-in-up:nth-child(10n) {
  animation-duration: 2.5s; }

.fade-out-down {
  animation: fade-out-down 1s both;
  transform-origin: top;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes fade-out-down {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(50px);
    opacity: 0; } }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: 01C 01D 018 019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button],
input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

/* normalize, clearfix, border-box, anti-aliasing, grey-scale */
*::selection {
  background: #fff0f5; }

html,
body {
  height: 100%; }

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  line-height: 1.4;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

p {
  margin: 2rem 0 0; }

a {
  display: inline-block;
  text-decoration: none;
  color: #2c638f; }

img {
  display: block; }

ul.large-text-list {
  padding: 0;
  list-style: none; }
  ul.large-text-list li {
    display: flex !important;
    align-items: center;
    padding: 1rem 0;
    font-weight: 600; }
    ul.large-text-list li.centered {
      justify-content: center; }
    ul.large-text-list li.with-icon:before {
      content: '';
      display: inline-block;
      height: 1.5rem;
      background-image: url("/assets/images/checked_icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 3.5rem;
      vertical-align: middle;
      stroke: #00c9b7; }

label {
  display: block; }

input, textarea {
  display: block;
  width: 100%;
  padding: 1rem 1.75rem;
  margin: 1rem 0;
  border: 4px solid #D6EDEB;
  border-radius: 8px;
  transition: all 0.25s linear; }
  input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover {
    outline: none;
    border: 4px solid #00c9b7; }

textarea {
  resize: vertical; }

.svg-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 2rem; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 4rem);
  width: calc(100vw - 4rem);
  margin: 2rem;
  border-radius: 25px;
  background: #fff;
  z-index: 999;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  transform: scale(0);
  transform-origin: center; }
  @media (min-width: 990px) {
    .modal {
      height: calc(100vh - 8rem);
      width: calc(100vw - 8rem);
      margin: 4rem; } }
  .modal.active {
    transform: scale(1); }
  .modal .modal-content {
    padding-top: 8rem;
    height: 100%; }
  .modal.active .modal-content {
    display: block; }

.iframe-wrapper {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  z-index: 1; }
  .iframe-wrapper iframe {
    height: 100%;
    width: 100%; }

.social-wrapper {
  position: absolute;
  bottom: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  padding-left: 1rem; }
  .social-wrapper img {
    margin-bottom: 1rem; }
  @media (min-width: 990px) {
    .social-wrapper {
      position: relative;
      bottom: auto;
      margin-top: 4rem; } }
  .social-wrapper:after {
    content: '';
    position: absolute;
    bottom: -8rem;
    left: calc(2rem - 1px);
    height: 7rem;
    width: 2px;
    background: #edf2f6; }

.cookies-message {
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 4.5rem;
  width: 100%;
  max-height: 50px;
  overflow: hidden; }
  @media (min-width: 990px) {
    .cookies-message {
      bottom: 0; } }
  .cookies-message p {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 10px;
    margin-top: 0%; }
    .cookies-message p .read-more {
      color: #013368; }

.button {
  outline: none;
  border: none;
  padding: 1rem 1.75rem;
  border-radius: 4px;
  font-weight: 600;
  background: transparent; }

.button-default {
  background: #00c9b7;
  border: 2px solid transparent !important;
  color: #fff;
  box-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
  max-width: 250px;
  transition: all 0.35s linear; }
  .button-default:hover {
    background: #00b0a0;
    transform: translateY(-2px); }

.button-green {
  background: #4CAF50;
  border: 2px solid transparent;
  color: #fff;
  box-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
  max-width: 250px;
  transition: all 0.35s linear; }
  .button-green:hover {
    background: #449d48;
    transform: translateY(-2px); }

.button-orange {
  background: #FFA721;
  border: 2px solid transparent;
  color: #fff;
  box-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
  max-width: 250px;
  transition: all 0.35s linear; }
  .button-orange:hover {
    background: #ff9d08;
    transform: translateY(-2px); }

.button-outline {
  border: 2px solid #00c9b7;
  color: #00c9b7;
  transition: all 0.35s linear; }
  .button-outline:hover {
    background: #00c9b7;
    color: #fff; }

.button-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3rem 4rem;
  color: #737687; }
  .button-close svg {
    width: 2rem;
    height: 2rem; }

.cookie-button {
  color: white;
  border: none;
  background-color: #00c9b7;
  border-radius: 4px;
  padding: 0.3rem 1rem;
  margin-left: 1rem;
  transition: all 0.35s linear;
  font-weight: normal; }
  .cookie-button:hover {
    background: #00b0a0;
    transform: translateY(-2px); }

.login-nav-button {
  color: white !important;
  background-color: #00c9b7;
  border: 1px solid #00c9b7;
  padding: 0.3rem 1rem;
  margin-left: 2rem;
  border-radius: 4px;
  transition: all 0.35s linear;
  font-weight: normal; }
  .login-nav-button a {
    color: #fff; }
  .login-nav-button:hover {
    background: #00b0a0;
    transform: translateY(-2px); }

@media (max-width: 500px) {
  .login-nav-button {
    color: white;
    background-color: #00c9b7;
    margin-left: 1.4rem;
    border: 1px solid;
    padding: 0.3rem 1rem;
    border-radius: 4px;
    transition: all 0.35s linear;
    font-weight: normal; }
  nav a.nav-link {
    height: 40px !important; } }

.hero-img {
  max-width: 100%;
  margin-top: 2rem;
  margin: 3rem 0;
  z-index: 2; }
  @media (min-width: 767px) {
    .hero-img {
      margin: 8rem auto; } }
  @media (min-width: 990px) {
    .hero-img {
      margin: 0; } }

.section-img {
  max-width: 100%;
  margin: 4rem auto 0;
  opacity: 0; }
  .section-img.section-img--wide {
    max-width: 150%;
    margin: 4rem 0 0 -25%; }
  @media (min-width: 767px) {
    .section-img {
      max-width: 75%; } }
  @media (min-width: 990px) {
    .section-img {
      max-width: 100%; } }
  @media (min-width: 1369px) {
    .section-img {
      max-width: 800px; } }

img.feature-img {
  max-width: 100%; }
  @media (min-width: 990px) {
    img.feature-img {
      margin: 0 auto; }
      img.feature-img.skew-left {
        transform: perspective(1000px) rotateY(8deg) translateX(5%); }
      img.feature-img.skew-right {
        transform: perspective(1000px) rotateY(-8deg) translateX(5%); } }

html {
  font-size: 10px;
  font-size: 0.9vw; }
  @media (max-width: 1111.11111px) {
    html {
      font-size: 10px; } }
  @media (min-width: 2444.44444px) {
    html {
      font-size: 22px; } }

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #424656; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  line-height: 1; }

h1 {
  font-size: 3.75rem; }

h2 {
  font-size: 3.5rem; }

h3 {
  font-size: 3rem; }

.price-cards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: auto;
  width: 85%; }
  @media screen and (max-width: 992px) {
    .price-cards {
      display: block; } }
  .price-cards .card {
    height: auto; }

.card-red {
  border-top: 5px solid rgba(243, 156, 156, 0.3) !important; }

.card-yellow {
  border-top: 5px solid rgba(250, 246, 117, 0.685) !important; }

.card-blue {
  border-top: 5px solid rgba(54, 159, 246, 0.349) !important; }

.card-green {
  border-top: 5px solid rgba(16, 200, 27, 0.3) !important; }

.card {
  height: 100%;
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px #e3e3e3;
  flex: 1;
  margin: 8px;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out; }
  .card:hover {
    cursor: pointer;
    box-shadow: 0 8px 20px #51cbee !important; }

.card-title h3 {
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 1rem; }

.card-title h4 {
  color: rgba(0, 0, 0, 0.6); }

.card-price {
  margin: 60px 0; }
  .card-price h1 {
    font-size: 46px;
    color: #292626; }
    .card-price h1 sup {
      font-size: 15px;
      display: inline-block;
      margin-left: -20px;
      width: 10px; }
    .card-price h1 small {
      color: rgba(0, 0, 0, 0.3);
      display: block;
      font-size: 11px;
      text-transform: uppercase; }

.card-description ul {
  display: block;
  list-style: none;
  margin: 60px 0 100px;
  padding: 0; }

.card-description li {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  margin: 0 0 15px; }

.price-card-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px auto;
  width: 90%; }
  .price-card-footer button {
    outline: 0;
    background: transparent;
    border: 2px solid #3498db;
    border-radius: 30px;
    color: #3498db;
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding: 15px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    font-size: 1.5rem; }
    @media screen and (min-width: 992px) {
      .price-card-footer button {
        font-size: 1rem; } }
    @media screen and (min-width: 1200px) {
      .price-card-footer button {
        font-size: 0.8rem; } }
    .price-card-footer button:hover {
      background-color: #3498db;
      box-shadow: 0 2px 4px #196090;
      color: #fff;
      font-size: 1; }

.card-bottom {
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 5px;
  flex: 1;
  padding: 1rem;
  position: relative;
  margin: auto;
  width: 100%;
  text-align: center; }

.card-action .button-free-trial {
  outline: 0;
  background: transparent;
  border: 2px solid #3498db;
  border-radius: 30px;
  color: #3498db;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
  width: 60%;
  height: 60px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out; }
  .card-action .button-free-trial:hover {
    background-color: #3498db;
    box-shadow: 0 2px 4px #196090;
    color: #fff;
    font-size: 15px; }

.currency-buttons {
  text-align: center; }
  @media (min-width: 767px) {
    .currency-buttons {
      float: right;
      clear: both;
      margin-right: 8rem; } }
  .currency-buttons input[type="radio"] {
    position: absolute;
    left: -9999em;
    top: -9999em; }
    .currency-buttons input[type="radio"] + label {
      float: left;
      padding: .5rem 1rem;
      cursor: pointer;
      margin-right: -1px;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out; }
      .currency-buttons input[type="radio"] + label:hover {
        box-shadow: 0 2px 4px #196090;
        background-color: #3498db;
        color: #fff; }
      .currency-buttons input[type="radio"] + label:first-of-type {
        border-radius: .1rem 0 0 .1rem; }
      .currency-buttons input[type="radio"] + label:last-of-type {
        border-radius: 0 .1em .1em 0; }
    .currency-buttons input[type="radio"]:checked + label {
      color: #fff;
      background-color: #3277b3; }

@media (max-width: 500px) {
  .button-group {
    margin-top: 3.5rem;
    font-size: 2rem;
    display: inline-block; }
  .section-stagger-price {
    opacity: 1 !important; } }

.card-action {
  position: absolute;
  width: 80%;
  bottom: 10px; }

.full_width {
  width: 100%; }

.blue {
  padding: 2px !important;
  color: #0d528f !important; }

.collection-item {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.description {
  margin: 0 8% 0 8%;
  padding-bottom: 2rem; }
  .description .text-secondary {
    color: #9d9191; }

header {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  z-index: 3;
  transition: background 0.5s linear, transform 0.25s ease-out; }
  @media (min-width: 990px) {
    header {
      bottom: auto;
      top: 2rem; } }
  header.scrolled {
    background: #fff; }
    @media (min-width: 990px) {
      header.scrolled {
        transform: translateY(-2rem); } }
    header.scrolled a.nav-link {
      color: #424656; }

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem; }
  @media (min-width: 990px) {
    nav {
      padding: 0 4rem; } }
  nav .logo {
    width: 50px; }
    @media (min-width: 990px) {
      nav .logo {
        width: 75px;
        margin-left: 4rem; } }
  nav a.nav-link {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    margin-left: 1.75rem;
    color: #424656;
    font-weight: 600;
    transition: color 0.25s linear;
    animation-delay: 0.5s; }
    @media (min-width: 990px) {
      nav a.nav-link {
        margin-left: 4rem; }
        nav a.nav-link:last-of-type:not(.dropdown-toggle) {
          margin-right: 4rem; } }
    nav a.nav-link:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #00c9b7;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .35s ease-out; }
    nav a.nav-link:hover:after {
      transform: scale(1); }
  nav .menu {
    background: white;
    padding: 1rem;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2); }
    nav .menu a {
      color: #424656; }
      nav .menu a:visited {
        color: #424656; }
  nav a.nav-link-dropdown {
    padding: 0.2rem 0.5rem;
    white-space: nowrap; }

nav a.fade-in:nth-child(1n) {
  animation-duration: 0.25s; }

nav a.fade-in:nth-child(2n) {
  animation-duration: 0.5s; }

nav a.fade-in:nth-child(3n) {
  animation-duration: 0.75s; }

nav a.fade-in:nth-child(4n) {
  animation-duration: 1s; }

nav a.fade-in:nth-child(5n) {
  animation-duration: 1.25s; }

nav a.fade-in:nth-child(6n) {
  animation-duration: 1.5s; }

nav a.fade-in:nth-child(7n) {
  animation-duration: 1.75s; }

nav a.fade-in:nth-child(8n) {
  animation-duration: 2s; }

nav a.fade-in:nth-child(9n) {
  animation-duration: 2.25s; }

nav a.fade-in:nth-child(10n) {
  animation-duration: 2.5s; }

footer {
  display: block;
  padding: 4rem 2rem calc(56px + 4rem);
  background: #424656;
  color: #fff; }
  @media (min-width: 990px) {
    footer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 8rem;
      grid-row-gap: 2rem;
      justify-items: stretch;
      padding: 2rem 4rem; } }

.hero-bg {
  position: absolute;
  max-width: 150%;
  top: -150px;
  right: 0;
  z-index: -1; }
  .hero-bg.bg-2 {
    display: none; }
    @media (min-width: 990px) {
      .hero-bg.bg-2 {
        display: block;
        max-width: 30%; } }
  @media (min-width: 767px) {
    .hero-bg {
      max-width: 125%;
      top: -200px;
      left: auto;
      right: -10%;
      z-index: -1; } }
  @media (min-width: 990px) {
    .hero-bg {
      top: 0;
      right: 0;
      max-width: 50%; } }

.section-bg {
  position: absolute;
  max-width: 100%;
  z-index: -1; }
  .section-bg.section-bg-360 {
    display: none; }
    @media (min-width: 990px) {
      .section-bg.section-bg-360 {
        display: block;
        top: 210vh;
        right: -30%;
        max-width: 50%; } }

section {
  position: relative;
  min-height: 100vh;
  padding: 8rem 2rem;
  overflow-x: hidden; }
  section .section-col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    section .section-col.section-col--desktop-only {
      display: block; }
      @media (min-width: 990px) {
        section .section-col.section-col--desktop-only {
          display: flex; } }
  section .section-stagger {
    display: inline-block;
    opacity: 0;
    will-change: transform; }
  section .section-fade {
    opacity: 0; }
  section.section--hero {
    height: 100px; }
    section.section--hero .hero-title {
      color: #fff; }
      @media (min-width: 990px) {
        section.section--hero .hero-title {
          color: #004A94; } }
    section.section--hero .hero-sub-title {
      color: rgba(255, 255, 255, 0.9);
      font-size: 1.5rem; }
      @media (min-width: 990px) {
        section.section--hero .hero-sub-title {
          color: #737687; } }
    section.section--hero .hero-controls {
      margin-top: 2rem;
      text-align: right; }
      @media (min-width: 990px) {
        section.section--hero .hero-controls {
          text-align: left; } }
  section.section--short {
    padding: 4rem 2rem;
    min-height: 100vh; }
    @media (min-width: 767px) {
      section.section--short {
        padding: 8rem 4rem; } }
    @media (min-width: 990px) {
      section.section--short {
        padding: 4rem 8rem; }
        section.section--short .section--hero-controls {
          margin-top: 4rem; } }
  section.section--single {
    grid-template-columns: 1fr; }
    section.section--single .section--inner {
      max-width: 1000px;
      margin: 0 auto; }
  @media (min-width: 990px) {
    section.section--promotion {
      grid-template-columns: 1fr;
      background: url("/assets/images/promote_lg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; } }
  @media (min-width: 767px) {
    section {
      padding: 8rem 4rem; } }
  @media (min-width: 990px) {
    section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 8rem;
      grid-row-gap: 2rem;
      justify-items: stretch;
      align-items: center;
      padding: 25vh 8rem; }
      section.section--feature {
        display: block;
        padding: 8rem; } }

.section-policy {
  display: block; }
  .section-policy li {
    margin-left: 4rem; }

.section--products {
  padding-top: 1rem;
  display: block; }

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  @media (min-width: 767px) {
    .products {
      flex-direction: row; } }
  .products .product-item {
    position: relative;
    background: #ffffff;
    transition: box-shadow 0.5s ease-in-out;
    display: block;
    min-height: 300px;
    margin-bottom: 4rem;
    width: 80%;
    text-align: center; }
    @media (min-width: 767px) {
      .products .product-item {
        width: 30%; } }
    .products .product-item h2 {
      font-size: 1.6rem;
      font-weight: 600; }
    .products .product-item:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.3);
      transition: opacity 0.3s ease-in-out; }
    .products .product-item:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out; }
    .products .product-item:hover:after {
      opacity: 1; }
    .products .product-item:hover:before {
      opacity: 0; }
    .products .product-item.tms:after {
      box-shadow: 0 8px 20px -3px rgba(0, 201, 183, 0.6); }
    .products .product-item.ajih:after {
      box-shadow: 0 8px 20px -3px rgba(255, 167, 33, 0.6); }
    .products .product-item.op:after {
      box-shadow: 0 8px 20px -3px rgba(76, 175, 80, 0.6); }
  .products .product-logo {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    height: 200px;
    padding-bottom: 2rem; }
    .products .product-logo img.tms {
      width: 70%; }
    .products .product-logo img.ajih {
      width: 40%; }
    .products .product-logo img.op {
      width: 90%; }

.bg-primary {
  background-color: #004A94 !important; }

.bg-light-grey {
  background-color: #e3e3e3 !important; }

.flex-on-lg {
  display: block; }
  @media (min-width: 990px) {
    .flex-on-lg {
      display: flex; }
      .flex-on-lg .flex-col-l {
        flex: 1;
        padding-right: 4rem; }
      .flex-on-lg .flex-col-r {
        flex: 1;
        padding-left: 4rem;
        padding-top: 0; } }
  .flex-on-lg .flex-col-r {
    padding-top: 4rem; }

.flex-center {
  display: flex !important;
  justify-content: center; }

.flex-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.grid-order-1 {
  order: 1; }

.grid-order-2 {
  order: 2; }

.grid-order-3 {
  order: 3; }

.h-100 {
  height: 100%; }

.hide-on-lg {
  display: block; }
  @media (min-width: 990px) {
    .hide-on-lg {
      display: none; } }

.m-pull-top-4x {
  margin-top: -4rem; }

.m-right-2x {
  margin-right: 2rem; }

.m-top-2x {
  margin-top: 2rem !important; }

.m-bot-2x {
  margin-bottom: 2rem !important; }

.m-top-3x {
  margin-top: 3rem !important; }

.m-top-4x {
  margin-top: 4rem !important; }

.m-top-6x {
  margin-top: 6rem !important; }

.no-margin {
  margin: 0; }

.p-bottom-0 {
  bottom: 0; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-absolute {
  position: absolute; }

.p-relative {
  position: relative; }

.lg-and-up {
  display: none; }
  @media (min-width: 990px) {
    .lg-and-up {
      display: block; } }

.hide-inline {
  display: none; }
  @media (min-width: 990px) {
    .hide-inline {
      display: inline-block; } }

.txt-a-center {
  text-align: center; }

.txt-a-right {
  text-align: right; }

.txt-c-orange {
  color: #FFA721 !important; }

.txt-c-green {
  color: #4CAF50 !important; }

.txt-c-primary {
  color: #004A94 !important; }

.txt-c-secondary {
  color: #00c9b7; }
  .txt-c-secondary:visited {
    color: #00c9b7 !important; }

.txt-c-white {
  color: #fff; }

.txt-c-grey {
  color: #737687 !important; }

.txt-c-trans-6 {
  color: rgba(255, 255, 255, 0.6); }

.txt-c-trans-8 {
  color: rgba(255, 255, 255, 0.8); }

.txt-c-white {
  color: #fff; }

.txt-lg {
  font-size: 1.2rem; }

.txt-x-lg {
  font-size: 1.5rem; }

.w-100 {
  width: 100%; }

.weight-600 {
  font-weight: 600; }

.d-inline-block {
  display: inline-block; }

.dropdown {
  display: inline-block;
  position: relative; }
  .dropdown .menu {
    color: #737687;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    left: -9999px;
    display: inline-block;
    opacity: 0;
    transition: opacity .5s ease-in-out; }
  .dropdown:hover .menu {
    opacity: 1;
    top: 90%;
    left: 0; }
