.button {
    outline: none;
    border: none;
    padding: 1rem 1.75rem;
    border-radius: 4px;
    font-weight: 600;
    background: transparent;
}

.button-default {
    background: $color-secondary;
    border: 2px solid transparent !important;
    color: $white;
    box-shadow: $box-shadow-primary;
    max-width: 250px;
    transition: all 0.35s linear;

    &:hover {
        background: darken($color-secondary, 5%);
        transform: translateY(-2px);
    }
}

.button-green {
    background: $color-green;
    border: 2px solid transparent;
    color: $white;
    box-shadow: $box-shadow-primary;
    max-width: 250px;
    transition: all 0.35s linear;

    &:hover {
        background: darken($color-green, 5%);
        transform: translateY(-2px);
    }
}

.button-orange {
    background: $color-orange;
    border: 2px solid transparent;
    color: $white;
    box-shadow: $box-shadow-primary;
    max-width: 250px;
    transition: all 0.35s linear;

    &:hover {
        background: darken($color-orange, 5%);
        transform: translateY(-2px);
    }
}


.button-outline {
    border: 2px solid $color-secondary;
    color: $color-secondary;
    transition: all 0.35s linear;

    &:hover {
        background: $color-secondary;
        color: #fff;
    }
}

.button-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3rem 4rem;
    color: $grey;
    
    svg {
        width: 2rem;
        height: 2rem;
    }
}

.cookie-button {
    color: white;
    border: none;
    background-color:#00c9b7;
    border-radius: 4px;
    padding: 0.3rem 1rem;
    margin-left: 1rem;
    transition: all 0.35s linear;
    font-weight: normal;

    &:hover {
        background: darken($color-secondary, 5%);
        transform: translateY(-2px);
    }
}
.login-nav-button {
    color: white !important;
    background-color:#00c9b7;
    border: 1px solid #00c9b7;
    padding: 0.3rem 1rem;
    margin-left: 2rem;
    border-radius: 4px;
    transition: all 0.35s linear;
    font-weight: normal;
    a {
        color: #fff;
    }

    &:hover {
        background: darken($color-secondary, 5%);
        transform: translateY(-2px);
    }
}
@media (max-width: 500px) {
    .login-nav-button {
        color: white;
        background-color:#00c9b7;
        margin-left: 1.4rem;
        border: 1px solid;
        padding: 0.3rem 1rem;
        border-radius: 4px;
        transition: all 0.35s linear;
        font-weight: normal;
    }
    nav {
        a.nav-link {
            height: 40px !important;
        }
    }
}
