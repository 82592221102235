$white: #fff;

$light-grey: #edf2f6;
$grey: #737687;
$dark-grey: #424656;

$black-ish: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

$color-primary: #004A94;
$color-secondary: #00c9b7;
$color-typography: #424656;
$color-primary-gradient: linear-gradient(to right bottom, #336ea9, #2865a4, #1c5c9f, #0f539a, #004a94);

$color-green: #4CAF50;
$color-orange: #FFA721;

$box-shadow-primary: 0 1px 3px rgba(36,180,126,.4);