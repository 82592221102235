.hero-bg {
    position: absolute;
    max-width: 150%;
    top: -150px;
    right: 0;
    z-index: -1;
    
    &.bg-2 {
      display:none;
      @include respond-to('large') {
        display:block;
        max-width: 30%;
      }
    }


  @include respond-to('medium') {
    max-width: 125%;
    top: -200px;
    left: auto;
    right: -10%;
    z-index: -1;
  }
  @include respond-to('large') {
    top: 0;
    right: 0;
    max-width: 50%;
  }
}

.section-bg {
    position: absolute;
    max-width: 100%;
    z-index: -1;

    &.section-bg-360 {
        display: none;

        @include respond-to('large') {
            display: block;
            top: 210vh;
            right: -30%;
            max-width: 50%;
        }
    }
}