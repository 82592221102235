section {
  position: relative;
  min-height: 100vh;
  padding: 8rem 2rem;
  overflow-x: hidden;

  .section-col {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.section-col--desktop-only {
      display: block;

      @include respond-to('large') {
        display: flex;
      }
    }
  }
  .section-stagger {
    display: inline-block;
    opacity: 0;
    will-change: transform;
  }

  .section-fade {
    opacity: 0;
  }

  &.section--hero {
    height: 100px;

    .hero-title {
      color: #fff;

      @include respond-to('large') {
        color: $color-primary;
      }
    }
    .hero-sub-title {
      color: rgba(255,255,255, 0.9);
      font-size: 1.5rem;

      @include respond-to('large') {
        color: $grey;
      }
    }
    .hero-controls {
      margin-top: 2rem;
      text-align: right;

      @include respond-to('large') {
        text-align: left;
      }
    }
  }

  &.section--short {
    padding: 4rem 2rem;
    min-height: 100vh;

    @include respond-to('medium') {
      padding: 8rem 4rem;
    }

    @include respond-to('large') {
      padding: 4rem 8rem;

      .section--hero-controls {
        margin-top: 4rem;
      }
    }
  }

  &.section--single {
    grid-template-columns: 1fr;

    .section--inner {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &.section--promotion {

      @include respond-to('large') {
          grid-template-columns: 1fr;
          background: url('/assets/images/promote_lg.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
      }
  }

  @include respond-to('medium') {
    padding: 8rem 4rem;
  }

  @include respond-to('large') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 8rem;
    grid-row-gap: 2rem;
    justify-items: stretch;
    align-items: center;
    padding: 25vh 8rem;

    &.section--feature {
      display: block;
      padding: 8rem;
    }
  }
}
.section-policy {
  display: block;

  li {
    margin-left: 4rem;
  }
}

.section--products {
  padding-top: 1rem;
  display: block;
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @include respond-to('medium') {
    flex-direction: row;
  }

  .product-item {
    position: relative;
    background: #ffffff;
    // border: 1px solid #eee;
    // box-shadow: 0 2px 10px -6px rgba(0,0,0,.4);
    transition: box-shadow 0.5s ease-in-out;
    display: block;
    min-height: 300px;
    margin-bottom: 4rem;
    width: 80%;
    text-align: center;

    @include respond-to('medium') {
      width: 30%;
    }

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      box-shadow: 0 5px 15px -8px rgba(0,0,0,0.3);
      transition: opacity 0.3s ease-in-out;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover:after {
      opacity: 1;
    }

    &:hover:before {
      opacity: 0;
    }

    &.tms:after {
      box-shadow: 0 8px 20px -3px rgba($color-secondary, 0.6);
    }
    &.ajih:after {
      box-shadow: 0 8px 20px -3px rgba($color-orange, 0.6);
    }
    &.op:after {
      box-shadow: 0 8px 20px -3px rgba($color-green, 0.6);
    }
  }

  .product-logo {
    width:60%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    height: 200px;
    padding-bottom: 2rem;

    img {
      &.tms {
        width: 70%;
      }
      &.ajih {
        width: 40%;
      }
      &.op {
        width: 90%;
      }
    }
  }
}