
.bg-primary {
    background-color: $color-primary !important;
}
.bg-light-grey {
    background-color: $light-grey !important;
}
.flex-on-lg {
    display: block;

    @include respond-to('large') {
        display: flex;

        .flex-col-l {
            flex: 1;
            padding-right: 4rem;
        }
        .flex-col-r {
            flex: 1;
            padding-left: 4rem;
            padding-top: 0;
        }
    }

    .flex-col-r {
        padding-top: 4rem;
    }
}
.flex-center {
    display: flex !important;
    justify-content: center;
}
.flex-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.grid-order-1 {
    order: 1;
}
.grid-order-2 {
    order: 2;
}
.grid-order-3 {
    order: 3;
}
.h-100 {
    height: 100%;
}
.hide-on-lg {
    display: block;
    @include respond-to('large') {
        display: none;
    }
}
.m-pull-top-4x {
    margin-top: -4rem;
}
.m-right-2x {
    margin-right: 2rem;
}
.m-top-2x {
    margin-top: 2rem !important;
}
.m-bot-2x {
    margin-bottom: 2rem !important;
}
.m-top-3x {
    margin-top: 3rem !important;
}
.m-top-4x {
    margin-top: 4rem !important;
}
.m-top-6x {
    margin-top: 6rem !important;
}
.no-margin {
    margin: 0;
}
.p-bottom-0 {
    bottom: 0;
}
.py-0 {
    padding-top:0 !important;
    padding-bottom:0 !important;
}
.p-absolute {
    position: absolute;
}
.p-relative {
    position: relative;
}
.lg-and-up {
    display: none;
    @include respond-to('large') {
        display: block;
    }
}
.hide-inline {
    display:none;
    @include respond-to('large') {
        display: inline-block;
    }
}
.txt-a-center {
    text-align: center;
}
.txt-a-right {
    text-align: right;
}
.txt-c-orange {
    color: $color-orange !important;
}
.txt-c-green {
    color: $color-green !important;
}
.txt-c-primary {
    color: $color-primary !important;
}
.txt-c-secondary {
    color: $color-secondary;
    &:visited {
        color: $color-secondary !important;
    }
}
.txt-c-white {
    color: $white;
}
.txt-c-grey {
    color: $grey !important;
}
.txt-c-trans-6 {
    color: rgba(255,255,255, 0.6);
}
.txt-c-trans-8 {
    color: rgba(255,255,255, 0.8);
}
.txt-c-white {
    color: $white;
}
.txt-lg {
    font-size: 1.2rem;
}
.txt-x-lg {
    font-size: 1.5rem;
}
.w-100 {
    width: 100%;
}
.weight-600 {
    font-weight: 600;
}

.d-inline-block {
    display: inline-block;
}

.dropdown {
    display: inline-block;
    position: relative;
    .menu {
        color: $grey;
        margin: 0;
        position: absolute !important;
        top: -9999px;
        left: -9999px;
        display: inline-block;
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
    &:hover {
        .menu {
            opacity: 1;
            top: 90%;
            left: 0;
        }
    }
}