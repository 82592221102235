header {
    position: fixed;
    bottom: 0rem;
    width: 100%;
    z-index: 3;
    transition: background 0.5s linear,
                transform 0.25s ease-out;

    @include respond-to('large') {
        bottom: auto;
        top: 2rem;
    }

    &.scrolled {
        background: #fff;

        @include respond-to('large') {
            transform: translateY(-2rem);
        }

        a.nav-link {
            color: $color-typography;
        }
    }
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;

    @include respond-to('large') {
        padding: 0 4rem;
    }

    .logo {
        width: 50px;

        @include respond-to('large') {
            width: 75px;
            margin-left: 4rem;
        }
    }

    .nav-links {
        // overflow-x: auto;
        // overflow-y: visible;
        // white-space: nowrap;
    }

    a.nav-link {
        display: inline-block;
        position: relative;
        height: 50px;
        line-height: 50px;
        margin-left: 1.75rem;
        color: $color-typography;
        font-weight: 600;
        transition: color 0.25s linear;
        animation-delay:  0.5s;

        @include respond-to('large') {
            margin-left: 4rem;

            &:last-of-type:not(.dropdown-toggle) {
                margin-right: 4rem;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $color-secondary;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .35s ease-out;
        }

        &:hover:after {
            transform: scale(1);
        }
    }
    .menu {
        background: white;
        padding: 1rem;
        box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);

        a {
            color: $color-typography;
            &:visited {
                color: $color-typography;
            }
        }
    }
    a.nav-link-dropdown {
        padding: 0.2rem 0.5rem;
        white-space:nowrap;
    }
}

@for $i from 1 through 10 {
    nav a.fade-in:nth-child(#{$i}n) {
        animation-duration: #{$i * .25}s;
    }
}