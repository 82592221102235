$primary-lighten: #3498db;
$primary-darken: #3277b3;
$red: #F39C9C;
$yellow: #FAF675;
$blue: #369FF6;
$green: #10C81B;
$light-grey: #e3e3e3;
$black: #000000;
$border-green: rgba($green, 0.3);
$border-blue: rgba($blue, 0.349);
$border-yellow: rgba($yellow, 0.685);
$border-red: rgba($red, 0.3);

.price-cards {
    display: flex;
    @media screen and (max-width: 992px) {
        display: block;
    }
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: auto;
    width: 85%;
    .card {
        height: auto;
    }
}
.card-red {
    border-top: 5px solid $border-red !important;
}
.card-yellow{
    border-top: 5px solid $border-yellow !important;
}
.card-blue {
    border-top: 5px solid $border-blue !important;
}
.card-green {
    border-top: 5px solid $border-green !important;
}
.card {
    height: 100%;
    border: 1px solid $light-grey;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 1px $light-grey;
    flex: 1;
    margin: 8px;
    padding: 30px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    &:hover {
        cursor: pointer;
        box-shadow: 0 8px 20px rgba(81, 203, 238, 1) !important;
    }
}
.card-title {
    h3 {
        color: rgba($black,0.3);
        font-size: 15px;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
    h4 {
        color: rgba($black,0.6);
    }
}
.card-price {
    margin: 60px 0;
    h1 {
        font-size: 46px;
        color: #292626;
        sup{
            font-size: 15px;
            display: inline-block;
            margin-left: -20px;
            width: 10px;
        }
        small {
            color: rgba(0,0,0,0.3);
            display: block;
            font-size: 11px;
            text-transform: uppercase;
        }
    }
}
.card-description {
    ul {
        display: block;
        list-style: none;
        margin: 60px 0 100px;
        padding: 0;
    }
    li {
        color: rgba(0,0,0,0.6);
        font-size: 15px;
        margin: 0 0 15px;
    }
}
.price-card-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 10px auto;
    width: 90%;
    button {
        outline: 0;
        background: transparent;
        border: 2px solid $primary-lighten;
        border-radius: 30px;
        color: $primary-lighten;
        cursor: pointer;
        display: block;
        font-weight: bold;
        padding: 15px;
        width: 100%;
        height: 60px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        font-size: 1.5rem;
        @media screen and (min-width: 992px) {
            font-size: 1rem;
        }
        @media screen and (min-width: 1200px) {
            font-size: 0.8rem;
        }
        &:hover {
            background-color: $primary-lighten;
            box-shadow: 0 2px 4px darken($primary-lighten,20);
            color: #fff;    // padding-left: 20px;

            font-size: 1    // padding-left: 20px;

        }
    }
}
.card-bottom {
    border: 1px solid $light-grey;
    background: #fff;
    border-radius: 5px;
    flex: 1;
    padding: 1rem;
    position: relative;
    margin: auto;
    width: 100%;
    text-align: center;
}
.card-action {
    .button-free-trial {
        outline: 0;
        background: transparent;
        border: 2px solid $primary-lighten;
        border-radius: 30px;
        color: $primary-lighten;
        cursor: pointer;
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding: 15px;
        width: 60%;
        height: 60px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: $primary-lighten;
            box-shadow: 0 2px 4px darken($primary-lighten,20);
            color: #fff;
            font-size: 15px;
        }
    }
}


.currency-buttons {
    text-align: center;
    @include respond-to('medium') {
        float: right;
        clear: both;
        margin-right: 8rem;
    }

    input[type="radio"] {
        position: absolute;
        left: -9999em;
        top: -9999em;

        & + label {
            float: left;
            padding: .5rem 1rem;
            cursor: pointer;
            margin-right: -1px;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 2px 4px darken($primary-lighten,20);
                background-color: $primary-lighten;
                color: #fff;
            }

            &:first-of-type {
                border-radius: .1rem 0 0 .1rem;
            }
            &:last-of-type {
                border-radius: 0 .1em .1em 0;
            }
        }
        &:checked + label {
            color: #fff;
            background-color: $primary-darken;
        }
    }
}
    @media (max-width: 500px) {
        .button-group {
            margin-top: 3.5rem;
            font-size: 2rem;
            display: inline-block;
        }
        .section-stagger-price {
            opacity: 1 !important;
        }
    }
    .card-action {
        position: absolute;
        width: 80%;
        bottom: 10px;
    }
    .full_width {
        width: 100%;
    }
    .blue {
        padding: 2px !important;
        color: #0d528f !important;
    }
.collection-item {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.description {
    margin: 0 8% 0 8%;
    padding-bottom: 2rem;
    .text-secondary {
        color: #9d9191;
    }
}

